<template>
  <div>
    <div class="tip" v-if="tip">
      <i class="el-alert__icon el-icon-info"></i>
      <div class="el-alert__content">
        <div class="el-alert__title">
          {{title}}
        </div>
      </div>
      <div class="el-alert__closebtn">
        <span class="active-color">查看详情</span>
        <i class="el-icon-close" @click="colse"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:['title'],
    data(){
      return {
        tip:true,
      }
    },
    methods:{
      colse(){
        this.tip = false;
        localStorage.setItem('tip',false);
      }
    },
    mounted(){
      const tip = localStorage.getItem('tip');
      if(localStorage.key('tip')) {this.tip = false};
    }
};
</script>

<style lang="scss" scoped>
.tip {
  width: 96%;
  padding: 9px 24px;
  margin: 0;
  border-radius: 2px;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  opacity: 1;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
  background: rgba(230, 247, 255, 1);
  border: 1px solid rgba(0, 160, 233, 0.5);
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  .el-icon-info {
    color: #00a0e9;
    font-size: 14px;
  }
  .el-alert__title {
    color: rgba(55, 69, 103, 0.85);
    font-size: 14px;
  }
  .el-alert__closebtn {
      top: 50%;
      transform: translateY(-50%);
    .el-icon-close {
      font-size: 9px;
      color: rgba(55, 69, 103, 0.45);
      margin-left: 26px;
    }
  }
}
</style>