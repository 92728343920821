<template>
  <div id="panel">
    <tip
      :title="'公告：山东省工业和信息化厅关于印发《山东省制造业创新中心建设工作指南》的通知'"
    />
    <div class="panel-box">
      <div
        @click="detailEvent(item)"
        class="panel-item"
        :class="'panel-item-' + (index + 1)"
        v-for="(item, index) in list"
        :key="item.name"
        :style="{
          backgroundImage:
            'url(' + require('../assets/img/' + item.enName + '.png') + ')',
        }"
      >
        <span v-if="!item.class">
          {{ item.name }}
        </span>
        <i :class="item.class" class="icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Tip from "@/components/tip.vue";
import { allModule } from "@/api/module";
export default {
  data() {
    return {
      list: [
        {
          name: "产业加加",
          path: "",
          enName: "panel_1",
        },
        {
          name: "个人中心",
          path: "",
          enName: "panel_5",
        },
        {
          name: "+",
          path: "",
          class: "el-icon-plus",
          enName: "panel_6",
        },
      ],
    };
  },
  components: {
    Tip,
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      allModule().then((res) => {
        if (res.status == 200) {
          const list = this.list;
          res.data.unshift(1, 0);
          Array.prototype.splice.apply(list, res.data);
          this.list = list;
          this.$forceUpdate();
        }
      });
    },
    detailEvent(item) {
      if (item.id) {
        this.$router.push({ path: "detail", query: { id: item.id } });
      } else {
        this.$message({
          message: "敬请期待",
          type: "success",
          center: true,
          duration:1000
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#panel {
  padding: 60px 32px 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: url("../assets/img/main_bg.png");
  background-size: 100% 100%;
  .panel-box {
    height: 100%;
    padding: 0 119px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    .panel-item {
      cursor: pointer;
      background: #27bbf5;
      height: 216px;
      line-height: 216px;
      font-size: 32px;
      width: 358px;
      color: #fff;
      margin-right: 32px;
      margin-bottom: 32px;
      .icon {
        font-size: 35px;
        font-weight: bold;
      }
    }
    .panel-item:nth-of-type(3n) {
      margin-right: 0;
    }
    @for $i from 1 through 6 {
      .panel-item-#{$i} {
        background-size: 100% 100%;
      }
    }
  }
  .el-row {
    flex-wrap: wrap;
  }
}
</style>